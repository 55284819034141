import React from "react"
import { Layout } from "@components/layout"
import { PostItem } from "@components/blog"
import {
  wrapper,
  tags,
  tags__headers,
  tags__items,
} from "./styles/tags.module.scss"

const Tag = ({ pageContext }) => {
  const { page, tag, posts } = pageContext

  return (
    <Layout {...page}>
      <div className={tags}>
        <div className={tags__headers}>
          <h1>#{tag.name}</h1>
        </div>
        <div className={tags__items}>
          {posts.map((el, k) => (
            <PostItem {...el} key={k} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Tag
